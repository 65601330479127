<template>
	<div id="appManage">
		<div class="el-content mb12">
			<div class="kd-pacel">搜索参数</div>
			<a-space>
				<a-input style="width:300px;" v-model:value="search.key" placeholder="应用名称" />
				<a-input style="width:300px;" v-model:value="search.mobile" placeholder="所属客户账号" />
				<a-button type="primary" @click="getProgramList(1)">
					<i class="ri-search-2-line"></i>
					查询
				</a-button>
				<router-link to="/admin/app/app_edit" v-has="{ action: '/admin/app/app_edit' }">
					<a-button type="primary">
						<i class="ri-add-line"></i>
						添加应用
					</a-button>
				</router-link>
			</a-space>
		</div>
		<div class="el-content">
			<a-tabs v-model:activeKey="search.status" @change="getProgramList(1,info.limit)">
				<a-tab-pane key="-1" tab="全部"></a-tab-pane>
				<a-tab-pane key="2" tab="使用中"></a-tab-pane>
				<a-tab-pane key="1" tab="已过期"></a-tab-pane>
			</a-tabs>
			<app-list :list="info.list" :pageCount="info.count" type="2" @changeList="getProgramList(info.page,info.limit)"></app-list>

			<div class="pager">
				<a-pagination
					show-size-changer
					:default-current="info.page"
					:total="info.count"
					:defaultPageSize="info.limit"
					@showSizeChange=" (page, e) => { getProgramList(info.page, e); } "
					@change=" e => { getProgramList(e, info.limit); } "
				/>
			</div>
		</div>
	</div>
</template>

<script>
import appList from '@/components/admin/appList.vue';
import { reactive, toRefs } from 'vue';
import programModel from '@/api/saas/app';
export default {
	components: {
		appList
	},
	setup(){
		const state = reactive({
			search:{key:"",mobile:'',status:'-1'},
			info: {
				list: [],
				page: 1,
				count: 0,
				limit: 10
			}
		})
		let width = document.body.clientWidth - 300;
		let limit = Math.floor(width / 300) * 3;
		state.info.limit = limit;
		getProgramList(1,state.info.limit)

		function getProgramList(page,limit) {
			programModel.getProgramList(2, page,limit,state.search,res=>state.info = {limit,...res})
		}

		return{
			...toRefs(state),
			getProgramList
		}
	}
}
</script>

<style lang="scss">
.app-search-input {
	width: 350px;
}
.kd-app-name {
	width: 100%;
	height: auto;
	display: flex;

	img {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		margin-right: 10px;
	}
}

.remove-app {
	&-seach {
		width: 100%;
		height: 50px;

		&-input {
			width: 400px;
		}
	}
	&-user {
		width: 100%;
		display: flex;
		flex-wrap: wrap;

		&-lo {
			width: 210px;
			height: 40px;
			display: flex;
			border: 1px solid #f1f1f1;
			padding: 10px 0 10px 10px;
			margin-bottom: 10px;
			cursor: pointer;
			margin-right: 10px;

			.lo-pic {
				width: 40px;
				height: 40px;
				border-radius: 50%;
				margin-right: 10px;
			}

			&-info {
				width: 80%;
				height: 100%;
			}
		}

		.li-active-border {
			border: 1px solid red;
		}
		.lo-active {
			position: absolute;
			margin-left: 176px;
			margin-top: 17px;
		}
	}
}
</style>
